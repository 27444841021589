import classes from './Sign.module.css'
import jackpot from '../../../assets/images/game-jackpot.png'
import jackpotCrop from '../../../assets/images/jackpot-crop.png'
import coin from '../../../assets/images/game-coin.png'
import { useSelector } from 'react-redux';

const Sign = ({ crop }) => {

  const user = useSelector((state) => state.user);

  return (
    <div className={classes.sign_container}>
      <div className={classes.sign}>
        {crop ?
          <img src={jackpotCrop} alt='jackpot' className={classes.crop}/>
        :
          <img src={jackpot} alt='jackpot' className={classes.jackpot}/>
        }
        <p className={classes.jackpotValue}>0,000,000 TON</p>
      </div>
      <div className={classes.sign}>
        <div className={classes.balance}>
          <img src={coin} alt='coin' className={classes.coin}/>
            {user.balance}
        </div>
      </div>
    </div>
  )
}

export default Sign;