import classes from './MenuItem.module.css'

const MenuItem = ({ title, img, clickHandler }) => {
  return (
    <div onClick={clickHandler} className={classes.MenuItem}>
      <img src={img} alt={title} className={classes.image}/>
    </div>
  )
}

export default MenuItem;