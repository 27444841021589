import classes from './Loading.module.css'
import { useEffect, useState } from 'react'

import wheelWrapper from '../../../assets/images/wheel-wrapper.png'
import wheelSpinner from '../../../assets/images/wheel-spinning.png'
import wheelJoker from '../../../assets/images/symbols/joker.png'

const Loading = ({ newUser }) => {
  const [progress, setProgress] = useState(3)
  useEffect(() => {
    setTimeout(() => setProgress(20), 500)
    setTimeout(() => setProgress(60), 1000)
    setTimeout(() => setProgress(90), 2000)
  }, [])
  return (
    <div className={classes.Loading} style={{background: `url(${process.env.PUBLIC_URL}/assets/bg-loading.svg) no-repeat center center/cover`}}>
      <h1 className={classes.title}>NAME PROJ</h1>
      <div className={classes.subcontainer}>
        <div className={classes.wheelContainer}>
          <img src={wheelWrapper} alt='wheel' className={classes.wheelWrapper}/>
          <div className={classes.wheelSpin}>
            <img src={wheelSpinner} alt='wheel' className={classes.wheelSpinner}/>
          </div>
          <img src={wheelJoker} alt='wheel' className={classes.wheelJoker}/>
        </div>
        <p className={classes.text}>Loading</p>
        <div className={classes.bar}>
          <div className={classes.percent} style={{width: `${progress}%`}}></div>
        </div>
      </div>
    </div>
  )
}

export default Loading;


