import classes from './BigTab.module.css'

const BigTab = ({ img, lvl, title, subtitle, rightContent, rightContentType, onClick }) => {
  return (
    <div className={classes.BigTab} onClick={onClick}>

      <div className={classes.left}>
        <div className={classes.BigTabIcon}>
        <img src={img} alt={title} className={classes.img}/>
          {lvl !== undefined &&
            <div className={classes.lvl}>
              {`${lvl} lvl`}
            </div>
          }
        </div>
        <div className={title === 'Mining' && classes.description}>
          <p className={classes.title}>{title}</p>
          <p className={classes.subtitle}>{subtitle}</p>
        </div>
      </div>

      <div className={rightContentType === 'main' ? classes.rightMain : classes.rightSub}>
        {rightContent}
      </div>

    </div>
  )
}

export default BigTab;