import BigTab from '../../ui/BigTab/BigTab';
import DecorativeTab from '../../ui/DecorativeTab/DecorativeTab';
import classes from './Earn.module.css'
import SmallTab from '../../ui/SmallTab/SmallTab';
import { useCallback, useDebugValue, useEffect, useMemo, useState } from 'react';
import DailyModal from '../../modals/DailyModal/DailyModal';
import ActionModal from '../../modals/ActionModal/ActionModal';
import { checkLinkTask, checkTask, getTasks } from '../../../api/taskApi';
import { setTasks, updateButtonState } from '../../../store/tasksSlice';
import { capitalizeFirstLetter } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance } from '../../../store/userSlice';
import Sign from '../../ui/Sign/Sign';
import bgFire from '../../../assets/images/bg-fire.png'


const Earn = ({ token, showSocial }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(<DailyModal onClose={() => setModalOpen(false)}/>)
  const dispatch = useDispatch()
  const { loadedTasks, dailyTasks, specialTasks, buttonsState } = useSelector((state) => state.tasks);
  const user = useSelector((state) => state.user);

  const fetchTasks = useCallback(async () => {
    if (!loadedTasks.length) {
      console.log(loadedTasks)
      const response = await getTasks(token)
      dispatch(setTasks(response))
    }
  }, [token, loadedTasks])

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks])

  const showDaily = () => {
    setModalContent(<DailyModal onClose={() => setModalOpen(false)}/>)
    setModalOpen(true)
  }

  const showAction = (token, img, titleTop, titleBot, id) => {
    setModalContent(
      <ActionModal
        token={token} 
        onClose={() => setModalOpen(false)} 
        img={''} 
        titleTop={titleTop} 
        titleBot={titleBot} 
        id={id}/>)
    setModalOpen(true)
  }

  const onLinkButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_success') {
      // Открываем ссылку, если задача ещё не началась
      tg.openLink(currentTask.link);
      dispatch(updateButtonState({ id: id, status: 'ready_to_claim'}));
    } else if (currentState?.status === 'ready_to_claim') {
      const response = await checkLinkTask(id, token)
      dispatch(updateBalance({ balance: Math.floor(response[0].new_balance)}))
      dispatch(updateButtonState({ id: id, status: 'success'}));
    }
  }

  const onSubButtonClick = async (id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
  
    if (currentState?.status === 'not_success') {
      // Открываем ссылку, если задача ещё не началась
      tg.openTelegramLink(currentTask.link);
      dispatch(updateButtonState({ id: id, status: 'started' })); // Обновляем статус на 'started'
    } else if (currentState?.status === 'started') {
      // Проверяем задачу, если она в статусе 'started'
      const response = await checkTask(id, token);
      if (response[0].status === 'success') {
        dispatch(updateButtonState({ id: id, status: 'ready_to_claim' })); // Обновляем статус на 'ready_to_claim'
      } else {
        showAction(token, '', currentTask.title, currentTask.description, id)
      }
    } else if (currentState?.status === 'ready_to_claim') {
      dispatch(updateBalance({ balance: user.balance + Math.floor(currentTask.reward)}))
      dispatch(updateButtonState({ id: id, status: 'success' }));
    } else if (currentState?.status === 'success') {
      console.log('claimed'); // Если задача уже выполнена
    }
  };
  
  const subButtonContent = (id) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим текущее состояние кнопки
  
    // Возвращаем текст для кнопки в зависимости от состояния
    switch (currentState?.status) {
      case 'not_success':
        return 'Start';
      case 'started':
        return 'Check';
      case 'ready_to_claim':
        return 'Claim';
      case 'success':
        return 'Done';
      default:
        return 'Unknown';
    }
  };
  
  const memoizedTasks = useMemo(() => (
    dailyTasks.map((task) => (
      <SmallTab
        key={task.id}
        img={''}
        titleTop={task.title}
        titleBot={task.description}
        subtitle={task.reward}
        buttonContent={subButtonContent(task.id)} // Текст кнопки в зависимости от состояния задачи
        onClick={task.task_type === 'link' ? () => onLinkButtonClick(task.id, token) : () => onSubButtonClick(task.id, token)} // Обработчик клика для кнопки
        opacity={buttonsState.find(item => item.id === task.id)?.status === 'success' ? '60%' : '100%'} // Если задача завершена, делаем прозрачность 60%
      />
    ))
  ), [dailyTasks, buttonsState]);

  const memoizedSpecTasks = useMemo(() => (
    specialTasks.map((task) => (
      <SmallTab
        key={task.id}
        img={''}
        titleTop={task.title}
        titleBot={task.description}
        subtitle={task.reward}
        buttonContent={subButtonContent(task.id)} // Текст кнопки в зависимости от состояния задачи
        onClick={task.task_type === 'link' ? () => onLinkButtonClick(task.id, token) : () => onSubButtonClick(task.id, token)} // Обработчик клика для кнопки
        opacity={buttonsState.find(item => item.id === task.id)?.status === 'success' ? '60%' : '100%'} // Если задача завершена, делаем прозрачность 60%
      />
    ))
  ), [specialTasks, buttonsState]);

  return (
    <div className={classes.Earn} style={{background: `url(${process.env.PUBLIC_URL}/assets/bg-app.svg) no-repeat center center/cover`}}>
      <div className={classes.menuFix}></div>
      {modalOpen && modalContent}
      <div className={classes.container}>
        <div className={classes.relative}>
          <Sign/>
        </div>

        <div className={classes.tasks}>
          <div className={classes.top20}>
              <img src={bgFire} alt='fire' className={classes.bgFire}/>
              <span className={classes.text20}>Compete tasks <br/>and earn money</span>
            </div>
          <div className={classes.task}>
            <p className={classes.taskTitle}>Special Tasks</p>
            {memoizedSpecTasks}
          </div>
          <div className={classes.task}>
            <p className={classes.taskTitle}>Daily Tasks</p>
            {memoizedTasks}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Earn;