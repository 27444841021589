import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { path } from "./path";

export const decode = (token) => {
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.user_id;
    } catch (error) {
      console.log('Неверный токен', error);
      return null;
    }
  } else {
    console.log('Токен не найден в URL');
    return null;
  }
}

export const getToken = async () => {
  const tg = window.Telegram.WebApp.initData;
  const params = new URLSearchParams(tg);
  const query_id = params.get('query_id');
  const user = params.get('user');
  const auth_date = params.get('auth_date');
  const hash = params.get('hash');

  console.log(params)
  try {
    const response = await axios.post(`${path}/auth-telegram`, {
        query_id: query_id,
        user: user,
        auth_date: auth_date,
        hash: hash
    });

    return response.data;

  } catch (err) {
    console.error('Ошибка при получении токена', err);
    return null;
  }
}

export const getUser = async (token) => {
  try {
    const response = await axios.get(`${path}/users/${decode(token)}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;

  } catch (err) {
    console.error('Ошибка при получении данных пользователя', err);
    return null;
  }
}