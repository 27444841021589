import classes from './UserTab.module.css'

const UserTab = ({ img, name, income, rightTitle, rightContent, color, border}) => {

  return (
    <div className={classes.UserTab}>
      
      <div className={classes.userData}>
        <div className={classes.border}>
          <div className={classes.blur}></div>
          <img src={img} alt={name} className={classes.img}/>
        </div>
        <div className={classes.info}>
          <p className={classes.name}>{name}</p>
          <p className={classes.income}>{income} JCoins</p>
        </div>
      </div>
      <div className={classes.right}>
        <p className={classes.rightTitle}>{rightTitle}</p>
        <p className={classes.rightContent}>{rightContent}</p>
      </div>
    </div>
  )
}

export default UserTab;