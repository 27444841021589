import { useEffect, useState, useRef } from 'react';
import { decode } from './userApi';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance, updateBalanceAndEnergy, updateEnergy, updateLeague } from '../store/userSlice';  // Импортируйте экшен
import { setReels, setTotalPrize, setWinningLines } from '../store/slotSlice';

export const useTapSocket = (token) => {
  const dispatch = useDispatch(); // Используем dispatch для вызова экшенов
  const [message, setMessage] = useState('');
  const ws = useRef(null);
  const debounceTimeoutRef = useRef(null);
  
  useEffect(() => {
    if (!token) return;

    const wsUrl = `wss://tap.jokerspin.online/ws/${decode(token)}/${token}`;
    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log("Connected to WebSocket");
      const taps = localStorage.getItem('taps')
      if (taps > 0) {
        sendTap(taps)
        localStorage.setItem('taps', JSON.stringify(0))
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      ws.current.close();
    };
  }, [token, dispatch]);

  const sendTap = (value) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      return new Promise((resolve, reject) => {
        // Пример отправки сообщения через вебсокет
        ws.current.send(value);
  
        // Обработчик события получения ответа
        ws.current.onmessage = (event) => {
          const data = event.data;
          console.log("Message from server:", data);
    
          const jsonString = data.replace('Message from server: ', '');
          const parsedData = JSON.parse(jsonString);
    
          const balance = parsedData.balance;
          const energy = parsedData.energy;
          const reels = parsedData.reels;
          const winning_lines = parsedData.winning_lines;
          const total_prize = parsedData.total_prize;
    
          dispatch(updateBalance({ balance: balance }))
          dispatch(updateEnergy({ energy: energy }))
          dispatch(setReels(reels))
          dispatch(setWinningLines(winning_lines))
          dispatch(setTotalPrize(total_prize))
          resolve()
        };
  
        // Обработчик ошибок
        ws.current.onerror = (error) => {
            reject(error);
        };
      });
    }
  };

  return {
    message,
    sendTap,
  };
};
