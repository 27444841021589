import React, { useCallback, useEffect, useState } from 'react';
import classes from './SlotMachine.module.css';
import { useSelector } from 'react-redux';
import slotInterface from '../../../assets/images/slot-inteface.png'

import A from '../../../assets/images/symbols/A.png'
import B from '../../../assets/images/symbols/B.png'
import C from '../../../assets/images/symbols/C.png'
import D from '../../../assets/images/symbols/D.png'
import Joker from '../../../assets/images/symbols/joker.png'
import Ton from '../../../assets/images/symbols/Ton.png'

import btnActive from '../../../assets/images/game-button-active.png'
import btnUnactive from '../../../assets/images/game-button-unactive.png'

const length = 40

const lines = [
  [[0, length - 3], [1, length - 3], [2, length - 3], [3, length - 3], [4, length - 3]],
  [[0, length - 2], [1, length - 2], [2, length - 2], [3, length - 2], [4, length - 2]],
  [[0, length - 1], [1, length - 1], [2, length - 1], [3, length - 1], [4, length - 1]],
  [[0, length - 3], [1, length - 2], [2, length - 1], [3, length - 2], [4, length - 3]],
  [[0, length - 1], [1, length - 2], [2, length - 3], [3, length - 2], [4, length - 1]],
  [[0, length - 3], [1, length - 1], [2, length - 3], [3, length - 1], [4, length - 3]],
  [[0, length - 1], [1, length - 3], [2, length - 1], [3, length - 3], [4, length - 1]],
]

const images = {
  A: A,
  B: B,
  C: C,
  D: D,
  E: A,
  F: B,
  G: C,
  H: D,
  Joker: Joker,
  TON: Ton,
};

function findConsecutiveIndices(arr, wildSymbol) {
  if (arr) {
    const result = [];
    let count = 1; // Считаем последовательные символы
    let start = 0; // Индекс начала последовательности
    let currentSymbol = arr[0]; // Символ, который отслеживаем

    for (let i = 1; i <= arr.length; i++) {
      // Проверяем, является ли текущий элемент продолжением последовательности (тот же или wild)
      if (arr[i] === currentSymbol || arr[i] === wildSymbol) {
        count++;
      } else {
        // Если последовательность прервалась, и длина последовательности >= 3
        if (count >= 3) {
          for (let j = start; j < i; j++) {
            result.push(j);
          }
        }
        // Сбрасываем счетчик и начинаем новую последовательность с текущего символа
        count = 1;
        start = i;
        currentSymbol = arr[i]; // Обновляем текущий символ
      }
    }

    // Обработка последней последовательности, если она заканчивается на массиве
    if (count >= 3) {
      for (let j = start; j < arr.length; j++) {
        result.push(j);
      }
    }

    return result;
  }
}

const getElementsByIndexes = (array, indexes) => {
  if (array && indexes) {
    return indexes.map(index => array[index]);
  } else {
    return array
  }
};


const Reel = ({ items, spinning, fakeReels, zIndex, combinations, reelIndex }) => {
  const [isComboExist, setIsComboExist] = useState(false)

  useEffect(() => {
    if (combinations) {
      if (combinations[0]) {
        setIsComboExist(true)
      } else {
        setIsComboExist(false)
      } 
    } else {
      setIsComboExist(false)
    }
  }, [combinations])

  return (
    <div className={classes.reel}>
      <div className={spinning ? classes.spinning : classes.stopped} style={{opacity: zIndex === 0 ? '0%' : '100%'}}>
        {items.map((item, index) => (
          <div key={index} className={classes.slot}>
            <img src={images[item]} alt={item} className={classes.symbolImage} />
          </div>
        ))}
      </div>
      <div className={classes.fake} style={{opacity: zIndex === -1 ? '0%' : '100%'}}>
        {fakeReels.map((item, index) => (
          <div key={index} className={classes.slot}
            style={{
              color: isComboExist && combinations.some((combo) =>
                combo.some((citem) => reelIndex === citem[0] && index === citem[1])
              )
                ? 'gold'
                : 'white',
            }}
          >
            <img src={images[item]} alt={item} className={classes.symbolImage} />
          </div>
        ))}
      </div>
    </div>
  );
};

const SlotMachine = ({ sendTap }) => {
  const user = useSelector((state) => state.user);

  const serverReels = useSelector((state) => state.slot.reels);
  const winningLines = useSelector((state) => state.slot.winningLines);
  const totalPrize = useSelector((state) => state.slot.totalPrize);

  const [spinning, setSpinning] = useState(false);
  const [zIndex, setZIndex] = useState(0)
  const [localLines, setLocalLines] = useState([])
  const [combinations, setCombinations] = useState([])
  const [winningIndex, setWinningIndex] = useState([])

  const [reels, setReels] = useState([
    generateUniqueSymbols(),
    generateUniqueSymbols(),
    generateUniqueSymbols(),
    generateUniqueSymbols(),
    generateUniqueSymbols(),
  ]);

  const [fakeReels, setFakeReels] = useState([
    generateUniqueSymbols(),
    generateUniqueSymbols(),
    generateUniqueSymbols(),
    generateUniqueSymbols(),
    generateUniqueSymbols(),
  ])
  
  useEffect(() => {
    setReels(prevReels => prevReels.map((reel, index) => generateNewItems(reel, index)));
    setFakeReels(reels.map((reel, index) => generateFakeSlot(reel, index)));
  }, [serverReels])

  useEffect(() => {
    const serverLines = []
    const serverCombos = []
    const result = []
    winningLines.forEach((item) => serverLines.push(lines[item.line - 1]))
    winningLines.forEach((item) => serverCombos.push(findConsecutiveIndices(item.combination, 'Joker')))

    serverLines.forEach((item, index) => result.push(getElementsByIndexes(item, serverCombos[index])))
    setLocalLines([...serverLines])
    setCombinations([...result])
  }, [winningLines])

  useEffect(() => {
    console.log(localLines)
    console.log(combinations)
  }, [localLines])

  function generateUniqueSymbols() {
    const symbols = ["A", "B", "C", "D", "E", 'F', 'G', 'H', "Joker", "TON"];
    const allSymbols = symbols.concat(symbols, symbols, symbols);
    const shuffledSymbols = allSymbols.sort(() => Math.random() - 0.5);
    return shuffledSymbols.slice(0, 40);
  }
  
  const generateNewItems = (prevItems, index) => {
    const lastThree = prevItems.slice(0, 3);
  
    const allSymbols = ["A", "B", "C", "D", "E", 'F', 'G', 'H', "Joker", "TON"];
  
    const availableSymbols = allSymbols.concat(allSymbols, allSymbols, allSymbols)
  
    const shuffledAvailableSymbols = availableSymbols.sort(() => 0.5 - Math.random());
    const randomSymbols = shuffledAvailableSymbols.slice(0, 34);
  
    return [...serverReels[index], ...randomSymbols, ...lastThree,];
  };
  
  const generateFakeSlot = (prevItems, index) => {
    const lastThree = prevItems.slice(0, 3);
  
    const allSymbols = ["A", "B", "C", "D", "E", 'F', 'G', 'H', "Joker", "TON"];
  
    const availableSymbols = allSymbols.concat(allSymbols, allSymbols, allSymbols)
  
    const shuffledAvailableSymbols = availableSymbols.sort(() => 0.5 - Math.random());
    const randomSymbols = shuffledAvailableSymbols.slice(0, 34);
  
    return [...lastThree, ...randomSymbols, ...serverReels[index],];
  };

  const handleStop = () => {
    setZIndex(0)
    setSpinning(false)
  };

  const handleSpin = async () => {
    try {
      await sendTap('1')
      setZIndex(-1)
      setTimeout(() => {
        setSpinning(true);
        setTimeout(() => {
          handleStop()
        }, 4000)
      }, 500)
    } catch (error) {
      console.error('Ошибка при отправке сообщения вебсокету:', error);
    }
    
  };

  return (
    <div className={classes.SlotMachine}>
      <div className={classes.slots}>
        <img src={slotInterface} alt='slots' className={classes.slotInterface}/>
          <div className={classes.overlap}>
            {reels.map((items, index) => (
              <Reel
                key={index}
                items={items}
                fakeReels={fakeReels[index]}
                spinning={spinning}
                zIndex={zIndex}
                combinations={combinations}
                reelIndex={index}
              />
            ))}
          </div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.button} onClick={spinning ? () => {} : handleSpin}>
          {spinning ?
            <img src={btnUnactive} alt='btn' className={classes.buttonUnactive}/>
          :
            <img src={btnActive} alt='btn' className={classes.buttonActive}/>
          }
        </div>
        <div className={classes.energyMeter}>
          <div className={classes.max}>MAX</div>
          <div className={classes.min}>MIN</div>
          <div className={classes.hider} style={{height: `${user.energy / user.energy_boost_level * 100}%`, borderRadius: (user.energy / user.energy_boost_level * 100) < 100 ? '35px 35px 0 0' : '35px'}}></div>
          <div className={classes.energyPercent} style={{height: '100%'}}></div>
        </div>
      </div>
    </div>
  );
}

export default SlotMachine;
