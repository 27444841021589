import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTapSocket } from '../../../api/tapApi'
import classes from './Home.module.css';
import ClickBubble from '../../ui/ClickBubble/ClickBubble';
import Gekko from '../../ui/Gekko/Gekko';
import uuid4 from "uuid4";
import { balancePlusOne, updateBalance, updateEnergy, updateMiningSecodns } from '../../../store/userSlice';
import { claim } from '../../../api/boosterApi';
import { formatNumberToM } from '../../../utils';
import SlotMachine from '../SlotMachhine/SlotMachine';
import jackpot from '../../../assets/images/game-jackpot.png'
import coin from '../../../assets/images/game-coin.png'
import Sign from '../../ui/Sign/Sign';
//Плавные тапы
//Проверить онбординг

const leagues = [
  'Bronze', 'Silver', 'Gold',
  'Platinum', 'Diamond', 'Saphyre',
  'Emerald', 'Ruby', 'Obsidian',
  'White gold',
];

const Home = ({ sendTap, token }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <div className={classes.Home} style={{background: `url(${process.env.PUBLIC_URL}/assets/bg-app.svg) no-repeat center center/cover`}}>
      <div className={classes.container}>
        <Sign/>
        <div className={classes.main}>
          <SlotMachine sendTap={sendTap}/>
          <div className={classes.claim}>
            <div className={classes.energy}>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
