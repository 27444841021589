import { useDispatch, useSelector } from 'react-redux';
import { getTimeToRefill, refillEnergy, upgradeEnergy, upgradeMultitap } from '../../../api/boosterApi';
import BigTab from '../../ui/BigTab/BigTab';
import DecorativeTab from '../../ui/DecorativeTab/DecorativeTab';
import classes from './Boost.module.css'
import { updateBalance, updateEnergy, updateEnergyBoostLevel, updateEnergyRefillTime, updateMiningLevel, updateTapBoostLevel } from '../../../store/userSlice';
import { useEffect, useState } from 'react';
import { formatNumberToM, secondsToHMS } from '../../../utils';
import ActionModal from '../../modals/ActionModal/ActionModal';
import Sign from '../../ui/Sign/Sign';
import bgFire from '../../../assets/images/bg-fire.png'

const Boost = ({ token }) => {
  const user = useSelector((state) => state.user);
  const [seconds, setSeconds] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (token) {
      const fetch = async () => {
        const response = await getTimeToRefill(token)
        setSeconds(response.energy_refill_seconds)
      }
      fetch()
    }
  }, [])

  useEffect(() => {
    if (seconds !== 0) {
      const interval = setInterval(() => {
        setSeconds(seconds - 1)
      }, 1000)
  
      return () => clearInterval(interval)
    }
  }, [seconds])


  const energyRefillHandler = async () => {
    const response = await refillEnergy(token)
    if (response.status === 'success') {
      dispatch(updateEnergy({ energy: response.energy_boost_level }))
      const res = await getTimeToRefill(token)
      setSeconds(res.energy_refill_seconds)
    }
  }
  const multitapUpgradeHandler = async () => {
    const response = await upgradeMultitap(token)
    if (response.status === 'success') {
      dispatch(updateBalance({ balance: response.balance }))
      dispatch(updateTapBoostLevel({ tap_boost_level: response.tap_boost_level }))
    } else {
      showAction()
    }
  }
  const energyUpgradeHandler = async () => {
    const response = await upgradeEnergy(token)
    if (response.status === 'success') {
      dispatch(updateBalance({ balance: response.balance }))
      dispatch(updateEnergyBoostLevel({ energy_boost_level: response.energy_boost_level }))
    } else {
      showAction()
    }
  }

  const showAction = () => {
    setModalOpen(true)
  }

  return (
    <div className={classes.Boost} style={{background: `url(${process.env.PUBLIC_URL}/assets/bg-app.svg) no-repeat center center/cover`}}>
      <div className={classes.menuFix}></div>
      {modalOpen && 
        <ActionModal 
          onClose={() => setModalOpen(false)} 
          img={'/assets/coin.svg'} 
          titleTop={'Not enough G coin'} 
          buttonColor={'#FF6B6B'}
          buttonContent={'Back'}
          onClick={() => setModalOpen(false)}
        />
      }
      <div className={classes.container}>
        <div className={classes.relative}>
          <Sign/>
        </div>

        <div className={classes.boostsContainer}>
          <div className={classes.top20}>
            <img src={bgFire} alt='fire' className={classes.bgFire}/>
            <span className={classes.text20}>BOOST</span>
          </div>
          <div className={classes.boosts}>
            <BigTab 
              img={'/assets/boost-1.png'}
              title={'Multitap'}
              lvl={user.tap_boost_level}
              subtitle={'Increase your taps'}
              rightContent={formatNumberToM(1000 * (2**(user.tap_boost_level - 1)))}
              rightContentType={'main'}
              onClick={multitapUpgradeHandler}
            />
            <BigTab 
              img={'/assets/boost-2.png'}
              lvl={user.energy_boost_level / 500}
              title={'Energy limit'}
              subtitle={'Increase your energy limit'}
              rightContent={formatNumberToM(1000 * (2**(user.energy_boost_level / 500 - 1)))}
              rightContentType={'main'}
              onClick={energyUpgradeHandler}
            />
            <BigTab
              img={'/assets/boost-3.png'}
              title={'Full energy'}
              subtitle={'Completely fills your energy'}
              rightContent={seconds !== 0 ? secondsToHMS(seconds) : ''}
              rightContentType={'sub'}
              onClick={energyRefillHandler}
            />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Boost;