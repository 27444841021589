import classes from './Social.module.css'
import SmallTab from '../../ui/SmallTab/SmallTab';

import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionModal from '../../modals/ActionModal/ActionModal';
import { updateButtonState } from '../../../store/tasksSlice';
import { checkLinkTask, checkTask } from '../../../api/taskApi';
import { updateBalance } from '../../../store/userSlice';

const Social = ({ token }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState()
  const { loadedTasks, dailyTasks, specialTasks, buttonsState } = useSelector((state) => state.tasks);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch()

  const showAction = (token, img, titleTop, titleBot, id) => {
    setModalContent(
      <ActionModal
        token={token} 
        onClose={() => setModalOpen(false)} 
        img={img} 
        titleTop={titleTop} 
        titleBot={titleBot} 
        id={id}/>)
    setModalOpen(true)
  }

  const onLinkButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_success') {
      // Открываем ссылку, если задача ещё не началась
      tg.openLink(currentTask.link);
      dispatch(updateButtonState({ id: id, status: 'ready_to_claim'}));
    } else if (currentState?.status === 'ready_to_claim') {
      const response = await checkLinkTask(id, token)
      dispatch(updateBalance({ balance: Math.floor(response[0].new_balance)}))
      dispatch(updateButtonState({ id: id, status: 'success'}));
    }
  }

  const onSubButtonClick = async (id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
  
    if (currentState?.status === 'not_success') {
      // Открываем ссылку, если задача ещё не началась
      tg.openTelegramLink(currentTask.link);
      dispatch(updateButtonState({ id: id, status: 'started' })); // Обновляем статус на 'started'
    } else if (currentState?.status === 'started') {
      // Проверяем задачу, если она в статусе 'started'
      const response = await checkTask(id, token);
      if (response[0].status === 'success') {
        dispatch(updateButtonState({ id: id, status: 'ready_to_claim' })); // Обновляем статус на 'ready_to_claim'
      } else {
        showAction(token, '', currentTask.title, currentTask.description, id)
      }
    } else if (currentState?.status === 'ready_to_claim') {
      dispatch(updateBalance({ balance: user.balance + Math.floor(currentTask.reward)}))
      dispatch(updateButtonState({ id: id, status: 'success' }));
    } else if (currentState?.status === 'success') {
      console.log('claimed'); // Если задача уже выполнена
    }
  };
  
  const subButtonContent = (id) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим текущее состояние кнопки
  
    // Возвращаем текст для кнопки в зависимости от состояния
    switch (currentState?.status) {
      case 'not_success':
        return 'Start';
      case 'started':
        return 'Check';
      case 'ready_to_claim':
        return 'Claim';
      case 'success':
        return 'Done';
      default:
        return 'Unknown';
    }
  };
  
  const memoizedTasks = useMemo(() => (
    specialTasks.map((task) => (
      <SmallTab
        key={task.id}
        img={''}
        titleTop={task.title}
        titleBot={task.description}
        subtitle={task.reward}
        buttonContent={subButtonContent(task.id)} // Текст кнопки в зависимости от состояния задачи
        onClick={task.task_type === 'link' ? () => onLinkButtonClick(task.id, token) : () => onSubButtonClick(task.id, token)} // Обработчик клика для кнопки
        opacity={buttonsState.find(item => item.id === task.id)?.status === 'success' ? '60%' : '100%'} // Если задача завершена, делаем прозрачность 60%
      />
    ))
  ), [specialTasks, buttonsState]);
  
  return (
    <div className={classes.Social}>
      {modalOpen && modalContent}
      <div className={classes.banner}>
        <div className={classes.socials}>
          <img src={''} alt='tg'/>
          <img src={''} alt='yt'/>
          <img src={''} alt='x'/>
        </div>
        <div className={classes.title}>
          Social partner tasks<br/>Play and enjoy
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.tasks}>
          <div className={classes.task}>
            {memoizedTasks}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Social;