import DecorativeTab from '../../ui/DecorativeTab/DecorativeTab';
import classes from './League.module.css'
import UserTab from '../../ui/UserTab/UserTab';
import LeagueTab from '../../ui/LeagueTab/LeagueTab';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatNumberToM } from '../../../utils';
import { getTop, getWeekly } from '../../../api/leagueApi';
import { useDispatch, useSelector } from 'react-redux';
import { addLeagueData } from '../../../store/leagueSlice';
import Sign from '../../ui/Sign/Sign';

import UserJackpot from '../../../assets/images/user-jackpot.png'
import UserPlaceholder from '../../../assets/images/user-placeholder.png'
import bgFire from '../../../assets/images/bg-fire.png'


const League = ({ token }) => {
  const [currentLeague, setCurrentLeague] = useState(0)
  const [type, setType] = useState('total')
  const dispatch = useDispatch();
  const loadedLeagues = useSelector((state) => state.league.loadedLeagues);

  const leagueKey = `${currentLeague}_${type}`;
  const usersArray = loadedLeagues[leagueKey] || [];

  const fetchUsers = useCallback(async () => {
    
    if (!loadedLeagues[leagueKey]) {
      let users;
      if (type === 'total') {
        users = await getTop(currentLeague + 1, token);
      } else if (type === 'weekly') {
        users = await getWeekly(currentLeague + 1, token);
      }

      if (users) {
        const sortedUsers = users.sort((a, b) => {
          if (type === 'total') {
            return b.balance - a.balance;
          } else if (type === 'weekly') {
            return b.balance_tap_weekly - a.balance_tap_weekly;
          }
          return 0;
        });

        dispatch(addLeagueData({ leagueKey, users: sortedUsers }));
      }
    }
  }, [currentLeague, type, token, loadedLeagues, dispatch]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);


  const memoizedUsers = useMemo(() => (
    usersArray.map((user, index) => (
      <UserTab
        key={user.id}
        img={user.profile_photo_url ? user.profile_photo_url : '/assets/user-placeholder.png'}
        name={user.username}
        income={type === 'total' ? formatNumberToM(user.balance) : formatNumberToM(user.balance_tap_weekly)}
        rightTitle={'Place'}
        rightContent={`#${index + 1}`}
      />
    ))
  ), [usersArray, currentLeague, type]);

  
  return (
    <div className={classes.League} style={{background: `url(${process.env.PUBLIC_URL}/assets/bg-app.svg) no-repeat center center/cover`}}>
      <div className={classes.menuFix}></div>
      <div className={classes.container}>
        <div className={classes.relative}>
          <Sign crop={true}/>
          
          <div className={classes.UserTab}>
            <div className={classes.userData}>
              <div className={classes.border}>
                <div className={classes.blur}></div>
                <img src={UserPlaceholder} alt={'user'} className={classes.img}/>
              </div>
              <div className={classes.info}>
                <p className={classes.name}>{'Pavel Durov'}</p>
                <p className={classes.income}>{'12000'} joker</p>
              </div>
            </div>
            <div className={classes.right}>
              <img src={UserJackpot} alt='jackpot' className={classes.UserJackpot}/>
            </div>
          </div>

        </div>
        
        <div className={classes.friendsList}>
          <div className={classes.top20}>
            <img src={bgFire} alt='fire' className={classes.bgFire}/>
            <span className={classes.text20}>TOP 20</span>
          </div>
          <div className={classes.gap}>
            {memoizedUsers}
          </div>
        </div>
      </div>
    </div>
  )
}

export default League;