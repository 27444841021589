import classes from './StatsTab.module.css'

const StatsTab = ({ title, stat }) => {
  return (
    <div className={classes.StatsTab} style={{background: `url(${process.env.PUBLIC_URL}/assets/friends-bg.png) no-repeat center center/cover`}}>
      <p className={classes.title}>{title}</p>
      <p className={classes.stat}>{stat}</p>
    </div>
  )
}

export default StatsTab;