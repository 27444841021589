import { createSlice } from '@reduxjs/toolkit';

// Функция для генерации массива случайных символов
const generateRandomSymbols = () => {
  const symbols = ["A", "B", "C", "D", "E", "F", "G", "H", "Joker", "TON"];
  const randomReel = [];
  
  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * symbols.length);
    randomReel.push(symbols[randomIndex]);
  }

  return randomReel;
};

// Генерация начального состояния для барабанов (5 барабанов по 3 символа)
const generateInitialReels = () => {
  const reels = [];
  for (let i = 0; i < 5; i++) {
    reels.push(generateRandomSymbols());
  }
  return reels;
};

const initialState = {
  reels: generateInitialReels(),
  winningLines: [{
    line: -1,
    prize: 0,
  }],
  totalPrize: 0
};

const slotSlice = createSlice({
  name: 'slot',
  initialState,
  reducers: {
    setReels(state, action) {
      state.reels = action.payload;
    },
    setWinningLines(state, action) {
      state.winningLines = action.payload;
    },
    setTotalPrize(state, action) {
      state.totalPrize = action.payload;
    },
  },
});

export const { setReels, setWinningLines, setTotalPrize } = slotSlice.actions;

export default slotSlice.reducer;