import { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLink, getReferals } from '../../../api/friendsApi';
import DecorativeTab from '../../ui/DecorativeTab/DecorativeTab';
import classes from './Friends.module.css';
import Button from '../../ui/Button/Button';
import StatsTab from '../../ui/StatsTab/StatsTab';
import UserTab from '../../ui/UserTab/UserTab';
import { setReferrals } from '../../../store/friendsSlice';
import { setLink } from '../../../store/linkSlice';
import { formatNumberToM } from '../../../utils';
import Sign from '../../ui/Sign/Sign';
import bgFire from '../../../assets/images/bg-fire.png'



const Friends = ({ token }) => {
  const dispatch = useDispatch();
  const { loadedReferrals, totalFriends, premiumFriends } = useSelector((state) => state.friends);
  const link = useSelector((state) => state.link.link);

  const [copyMessage, setCopyMessage] = useState(''); // Состояние для сообщения о копировании

  const fetchLink = useCallback(async () => {
    console.log(link)
    if (link === '') {
      const response = await getLink(token);
      const invite_link = response.invite_link;
      dispatch(setLink(invite_link));
    }
  }, [token, link]);

  const fetchUsers = useCallback(async () => {
    if (loadedReferrals === 0) {
      const response = await getReferals(token);
      const users = response.referrals;
      console.log(users);
      if (users) {
        const sortedUsers = users.sort((a, b) => {
          return b.balance - a.balance;
        });
        dispatch(setReferrals(sortedUsers));
      }
    }
  }, [token, loadedReferrals, dispatch]);

  useEffect(() => {
    fetchLink();
  }, [fetchLink]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setCopyMessage('Copied to clipboard!'); // Установить сообщение
    setTimeout(() => {
      setCopyMessage(''); // Очистить сообщение через 2 секунды
    }, 2000);
  };

  const sendLink = () => {
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    const text = "\nPlay Gekko game with me and get a token AirDrop!\n💸 +2k Coins as a first-time gift\n🔥 +25k Coins if you have Telegram Premium";
    tg.openTelegramLink(`https://t.me/share/url?url=${link}&text=${encodeURI(text)}`);
  };

  const memoizedReferrals = useMemo(() => (
    loadedReferrals !== 0 && loadedReferrals.map((referral) => (
      <UserTab
        key={referral.id}
        img={referral.profile_photo_url ? referral.profile_photo_url : '/assets/defaultAva.png'}
        name={referral.username}
        income={formatNumberToM(referral.balance)}
        rightTitle={'Your reward'}
        rightContent={referral.earnings}
      />
    ))
  ), [loadedReferrals]);

  return (
    <div className={classes.Friends} style={{background: `url(${process.env.PUBLIC_URL}/assets/bg-app.svg) no-repeat center center/cover`}}>
      <div className={classes.menuFix}></div>
      <div className={classes.container}>
        <div className={classes.relative}>
          <Sign/>
        </div>
        
        {copyMessage && <div className={classes.copyMessage}>{copyMessage}</div>} {/* Вывод сообщения */}
        
        <div className={classes.friendsList}>
          <div className={classes.top20}>
            <img src={bgFire} alt='fire' className={classes.bgFire}/>
            <span className={classes.text20}>FRIENDS</span>
          </div>
          
            <div className={classes.buttonContainer}>
            <div 
              className={classes.mainButton}
              onClick={sendLink}
            >
              Invite
              <img src={'/assets/invite-icon.png'} alt='invite' className={classes.buttonImg}/>
            </div>
            <div className={classes.copyButton}>
              <div
                className={classes.copyB}
                onClick={copyLink}
              >
                <img src={'/assets/copy-icon.png'} alt='copy' className={classes.copyI}/>
              </div>
            </div>
          </div>

          <div className={classes.stats}>
            <StatsTab title={'Total friends'} stat={`${totalFriends}`}/>
            <StatsTab title={'Premium'} stat={`${premiumFriends}`}/>
            <StatsTab title={'Default'} stat={`${totalFriends - premiumFriends}`}/>
          </div>
          <div className={classes.over}>
            <div className={classes.gap}>
              {memoizedReferrals}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Friends;
